@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.d{
  color: #264446;
}

::selection{
  background-color: transparent;
  color: #FD562A;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

.meow::-webkit-scrollbar {
  width: 7px;
  display: none;
}

.meow::-webkit-scrollbar-thumb {
  background: hidden;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
  background: #FD562A;
  border-radius: 5px;
}
.active{
  border-radius: 2px;
  border-bottom: 3px solid #FD562A;
}

.li:hover{
  color: #1422e0;
}
.loose{
  line-height: 1.15 !important;
}
h1,h2,h3,h4,h5,h6,li,label{
  font-family: 'poppins';
}
.toggle-switch {
  position: relative;
  width: 100px;
  height: 50px;
  --light: #d8dbe0;
  --dark: #28292c;
  --link: rgb(27, 129, 112);
  --link-hover: rgb(24, 94, 82);
}

.switch-label {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: var(--dark);
  border-radius: 25px;
  cursor: pointer;
  border: 3px solid var(--dark);
}

.checkbox {
  position: absolute;
  display: none;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox:checked ~ .slider {
  background-color: var(--light);
}

.slider::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-box-shadow: inset 12px -4px 0px 0px var(--light);
  box-shadow: inset 12px -4px 0px 0px var(--light);
  background-color: var(--dark);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox:checked ~ .slider::before {
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  background-color: var(--dark);
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (max-width:500px) {
  .toggle-switch,.switch-label{
    width: 80px !important;
    height: 30px !important;
  }
  .slider::before {
    height: 15px;
    width: 15px;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    box-shadow: inset 6px -3px 0px 0px var(--light);
  }
}